<template>
    <div>
    <el-card class="box-card" shadow="never">
        <template #header>
            <div class="card-header">
                <span>查询</span>
            </div>
        </template>
        <div class="card-content">
            <el-form :inline="true" label-width="100px" label-position="right" class="responsive-form">
                <el-form-item label="结算时间：">
                    <el-date-picker type="date" v-model="serchDate.created_start" placeholder="请输入创建时间"
                        value-format="YYYY-MM-DD" class="date-picker" style="margin-right: 10px;"/>
                    <el-date-picker type="date" v-model="serchDate.created_end" placeholder="请输入截至时间"
                        value-format="YYYY-MM-DD" class="date-picker"/>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="handlSearch()">查询</el-button>  
                </el-form-item>
            </el-form>
        </div>
    </el-card>
</div>

    <div class="content">
        <el-row :gutter="20" justify="space-between">
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>总收款</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ dealerData.pay_amount > 0 ? dealerData.pay_amount : 0 }}元</span>
                        </div>
                        <div>
                            <span>订单量</span>
                            <span>{{ dealerData.pay_total > 0 ? dealerData.pay_total : 0 }}笔</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>总退款</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span> {{ dealerData.refund_amount > 0 ? dealerData.refund_amount : 0 }}元</span>
                        </div>
                        <div>
                            <span>订单量</span>
                            <span>{{ dealerData.refund_total > 0 ? dealerData.refund_total : 0 }}笔</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>实际收款</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ parseFloat(dealerData.pay_amount - dealerData.refund_amount).toFixed(2) > 0 ?
                        parseFloat(dealerData.pay_amount - dealerData.refund_amount).toFixed(2) : 0 }}元</span>
                        </div>
                        <div>
                            <span>实收金额</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>手续费</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ dealerData.fee_amount > 0 ? dealerData.fee_amount : 0 }}笔</span>
                        </div>
                        <div>
                            <span>手续费</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>结算金额</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ parseFloat(dealerData.pay_amount - dealerData.refund_amount -
                        dealerData.fee_amount).toFixed(2) > 0 ? parseFloat(dealerData.pay_amount -
                            dealerData.refund_amount -
                            dealerData.fee_amount).toFixed(2) : 0 }}元</span>
                        </div>
                        <div>
                            <span>结算金额</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
    <div class="detail">
        <el-card shadow="never" class="card-border" v-loading="loading">
            <template #header>
                <div class="card-header">
                    <span>支付汇总</span>
                </div>
            </template>
            <div>
                <CustomTable :data="statisticPayment" />

            </div>
        </el-card>
    </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref } from "vue";
import { GetToday } from "../../tools/dateTools"
import CustomTable from './CustomTable.vue';

const { proxy } = getCurrentInstance()
const loading = ref(true)

const dealerData = ref([])
const statisticPayment = ref([])


//查询
const handlSearch = () => {
    getStatistic(serchDate.value)
}

const getStatistic = async (data) => {
    loading.value = true;
    try {
        const res = await proxy.$api.count.getStatistic(data);

        if (res.status === 200 && res.success) {
            const { statisticStores = [], statisticPaymentMethod = {} } = res.data;
            const { wxpay = [], alipay = [], unionpay = [] } = statisticPaymentMethod;

            dealerData.value = statisticStores[0] || {}; 

            statisticPayment.value = [
                {
                    title: "微信",
                    pay_total: wxpay[0]?.pay_total || "0",
                    refund_total: wxpay[0]?.refund_total || "0",
                    refund_amount: wxpay[0]?.refund_amount || "0.00",
                    fee_amount: wxpay[0]?.fee_amount || "0.00",
                    pay_amount: wxpay[0]?.pay_amount || "0.00"
                },
                {
                    title: "支付宝",
                    pay_total: alipay[0]?.pay_total || "0",
                    refund_total: alipay[0]?.refund_total || "0",
                    refund_amount: alipay[0]?.refund_amount || "0.00",
                    fee_amount: alipay[0]?.fee_amount || "0.00",
                    pay_amount: alipay[0]?.pay_amount || "0.00"
                },
                {
                    title: "云闪付",
                    pay_total: unionpay[0]?.pay_total || "0",
                    refund_total: unionpay[0]?.refund_total || "0",
                    refund_amount: unionpay[0]?.refund_amount || "0.00",
                    fee_amount: unionpay[0]?.fee_amount || "0.00",
                    pay_amount: unionpay[0]?.pay_amount || "0.00"
                }
            ];
        }
    } catch (error) {
        console.error("Error fetching statistics:", error);
    } finally {
        loading.value = false;
    }
}

//初始页
const serchDate = ref({
    created_start: GetToday(),
    created_end: GetToday(),
})

onMounted(() => { getStatistic(serchDate.value) })

</script>


<style lang="less" scoped>
.container {
    width: 100%;
    box-sizing: border-box;

    .box-card {
        border-top: 5px solid #e7e7e7;
    }

    .card-header {
        span {
            font-size: 15px;
            font-weight: bold;
        }
    }
}

.content {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;
        margin-top: 10px;

        span {
            font-size: 15px;
            font-weight: bold;
        }
    }

    .card-content {
        box-sizing: border-box;

        h2 {
            span {
                font-size: 16px;
                margin-left: 6px;
            }
        }

        div:nth-child(1) {
            font-size: 23px;
            padding-left: 6px;
        }

        div:nth-child(2) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;

            span {
                font-size: 14px;
                margin-left: 6px;
            }

            span:nth-child(2) {
                color: #409EFF;
            }
        }
    }
}

.detail {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }

        .pagination {
            display: flex;
            justify-content: flex-end;
        }
    }

}
</style>
