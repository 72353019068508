export const fixed = (num) => {
    return Number(num).toFixed(2)
}

// UTC转换为本地当前时间
export const ConversionTime =function(time){
	const v  =new Date(time)
	const year = v.getFullYear()
	// const month = v.getMonth() + 1 < 10 ? `0${v.getMonth() + 1 }`: v.getMonth()
	const month = v.getMonth() + 1 
	const day = v.getDate() + 1 < 10 ? `0${v.getDate()}`: v.getDate()
	const hour = v.getHours() < 10 ? `0${v.getHours()}`: v.getHours()
	const minute = v.getMinutes() < 10 ? `0${v.getMinutes()}`: v.getMinutes()
	const second = v.getSeconds() < 10 ? `0${v.getSeconds()}`: v.getSeconds()
	return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}


// 获取今日起始点日期 2020-01-01 00:00:00
export const getTodayStart = () => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
}

// 获取今日实时日期 2020-01-01 23:59:59
export const getTodayEnd = () => {
    const date = new Date()
    date.setHours(23, 59, 59, 999)
    return date
}

export function timeFormat(time, format = 'date') {
    const symbolType = '-'

    const date = new Date(time)
    const Y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const H = getFullTime(date.getHours())
    const i = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    if (format == "date") {
        return Y + symbolType + m + symbolType + d
    }

    if (format == 'datetime') {
        return Y + symbolType + m + symbolType + d + " " + H + ":" + i + ":" + s
    }
}

export function getFullTime(time) {
    return time < 10 ? "0" + time.toString() : time.toString()
}

export function format(time) {
    const symbolType = '-'

    const date = new Date(time)
    const Y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const H = getFullTime(date.getHours())
    const i = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    return Y + symbolType + m + symbolType + d + " " + H + ":" + i + ":" + s
}

const time = new Date()


// 获取当前时间
export const GetCurrentTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds())
}

//获取今天结束时间
export const GetTodayEndTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + "23" + ":" + "59" + ":" + "59")
}
// 获取昨天开始时间
export const GetYesterdayStartTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + (time.getDate() - 1) + " " + "00" + ":" + "00" + ":" + "00")
}

// 获取昨天结束时间
export const GetYesterdayEndTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + (time.getDate() - 1) + " " + "23" + ":" + "59" + ":" + "59")
}


//获取今天开始时间
export const GetTodayStartTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + "00" + ":" + "00" + ":" + "00")
}


//截取当前时间年月日
export const GetToday = () => {
    return (time.getFullYear() + "-" + ((time.getMonth() + 1)<10?"0"+(time.getMonth() + 1):(time.getMonth() + 1)) + "-" + (time.getDate()<10?"0"+time.getDate():time.getDate()))
}

