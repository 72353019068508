<template>
    <el-table :data="data" stripe border style="width: 100%; margin-top: 20px;" fit>
      <el-table-column prop="title" label="支付方式"></el-table-column>
      <el-table-column prop="pay_total" label="交易笔数"></el-table-column>
      <el-table-column prop="refund_total" label="退款笔数"></el-table-column>
      <el-table-column prop="refund_amount" label="退款金额"></el-table-column>
      <el-table-column prop="fee_amount" label="手续费金额"></el-table-column>
      <el-table-column label="实收金额">
        <template v-slot="scope">
          {{ parseFloat(scope.row.pay_amount - scope.row.refund_amount).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column label="结算金额">
        <template v-slot="scope">
          {{ parseFloat(scope.row.pay_amount - scope.row.refund_amount - scope.row.fee_amount).toFixed(2) }}
        </template>
      </el-table-column>
    </el-table>
  </template>
  
  <script setup>
  import { defineProps} from 'vue';
  import { onMounted} from "vue";
  const props = defineProps(['data']);
  onMounted(() => { console.log(props); })
  </script>
  